.container {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 30px;
  color: var(--primary);
}
