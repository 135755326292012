.container {
  text-align: center;
  color: #333;
}

h1 {
  margin-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

a {
  text-decoration: none;
  color: #007bff;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 8px;
}

.link {
  color: var(--text);
  text-decoration: none;
  position: relative;
  transition: color 0.3s;
}

.link:hover {
  color: var(--accent);
}
