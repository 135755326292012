.slideshowContainer {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  max-height: 300px;
  display: flex;
  overflow: hidden;
  border: 2px solid #ccc;
  border-radius: 10px;
}

.slideImage {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  flex: 0 0 100%;
  transition: transform 0.3s ease-out;
}

.slideImage.fadeOut {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.slideButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slideButton:nth-child(2) {
  left: 10px;
}

.slideButton:last-child {
  right: 10px;
}
