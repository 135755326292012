.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 0 20px;
}

.projectItem {
  background: var(--secondary);
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
  overflow: hidden;
}

.projectItem:hover {
  transform: translateY(-5px);
}

.projectThumbnail {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
}

.projectTitle {
  font-size: 1.2rem;
  color: var(--text);
  padding: 10px;
}
