.navbar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--accent);
  padding: 0 20px;
  margin: 0 auto 0 auto;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.navbar li {
  margin: 0 20px;
}

.navbar a {
  color: var(--text);
  text-decoration: none;
  position: relative;
  transition: color 0.3s;
}

.navbar a:hover {
  color: var(--accent);
}
